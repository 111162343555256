import React from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import posed from 'react-pose';

import { smooth } from '../../SiteLayout';
import washout from '../../images/washout.png';


export default class SloganBlock extends React.PureComponent {

    state = { pose: 'init' }

    componentDidMount() {
        setTimeout(() => smooth.on('call', () => {
            this.setState({ pose: 'moved' });
        }), 100);
    }

    render() {
        const { pose } = this.state;
        return (
            <Root
                data-scroll
                data-scroll-call='slogan'
                data-scroll-offset='60%'
            >
                <Washout
                    data-scroll
                    data-scroll-speed={3}
                    pose={pose}
                    src={washout}
                    alt='washout'
                />
                <Line pose={pose} />
                <Box pose={pose}>
                    <Slogan>
                        I am Regulus.
                        <Media query='(max-width: 800px)'>
                            {m => m ? (<br />) : (' ')}
                        </Media>
                        My mere utterance is law.
                    </Slogan>
                </Box>
                <Line pose={pose} />
            </Root>
        )
    }
}

const Root = styled.section`
    height: 150vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Washout = posed(styled.img`
    position: absolute;
    height: auto;
    width: 65vw;

    @media screen and (max-width: 800px) {
        width: 75vw;
    }
`)({
    init: {
        opacity: 0
    },
    moved: {
        opacity: 0.75,
        transition: {
            type: 'tween',
            duration: 1000,
            ease: 'easeInOut',
            delay: 500
        }
    }
});

const Slogan = styled.p`
    font-family: Vollkorn;
    font-size: 24px;
    color: white;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: 22px;
    }
`;

const Box = posed(styled.div`
    width: 70vw;
    height: 75px;
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    z-index: 10;

    @media screen and (max-width: 400px) {
        width: 90vw;
    }
`)({
    init: {
        opacity: 0,
        scale: 0.75
    },
    moved: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 2000,
            ease: 'easeInOut'
        }
    }
});

const Line = posed(styled.div`
    height: 175px;
    min-height: 175px;
    width: 1px;
    background: white;
`)({
    init: {
        opacity: 0,
        margin: 200
    },
    moved: {
        opacity: 1,
        margin: 40,
        transition: {
            type: 'tween',
            duration: 2000,
            ease: 'easeInOut'
        }
    }
});