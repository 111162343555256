import React from 'react';
import './hamburgers.css'

export default ({ onClick, type, active, dark }) => (
	<div className="nav__hamburger">
		<button
			onClick={onClick}
			className={
				`hamburger hamburger--${type}${active ? ' is-active' : ''}${dark ? ' dark' : ''}`
			}
			type="button" id="hamburger"
			aria-label="menu"
		>
			<span className="hamburger-box">
				<span className="hamburger-inner"></span>
			</span>
		</button>
	</div>
);
