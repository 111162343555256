import React from 'react';
import styled from 'styled-components';

import { smooth } from '../../SiteLayout';


export default class SquareDecorBlock extends React.PureComponent {

    state = { y: 0 }

    componentDidMount() {
        setTimeout(() => smooth.on('scroll', this.handleScroll), 100);
    }

    handleScroll = ({ scroll }) => {
        this.setState({ y: (scroll.y / 10) - 200 });
    }

    centerSquares = () => {
        smooth.scrollTo(document.querySelector('#home'), 2000);
    }

    render() {
        const { y } = this.state;
        return (
            <Root data-scroll>
                <Square onClick={this.centerSquares} y={y}>
                    <Square2 y={y}>
                        <Square3 y={y}>
                            <Square4 y={y}>
                                <Square5 y={y} />
                            </Square4>
                        </Square3>
                    </Square2>
                </Square>
            </Root>
        )
    }
}

const Root = styled.section`
    height: 200vh;
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

const opacity = 0.15;

const Square = styled.div`
    height: 75vw;
    width: 75vw;
    border: 1px solid white;
    position: relative;
    transform: rotate(${({ y }) => y * 1}deg);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,${opacity});

    /* box-shadow: 0px 0px 20px -4px rgba(0,0,0,1); */

    /* :nth-of-type(2) {
        transform: scale(0.85) rotate(${({ y }) => y * 1.5}deg);
        background: rgba(255, 255, 0, 0);
    }
    :nth-of-type(3) {
        transform: scale(0.7) rotate(${({ y }) => y * 2}deg);
        background: rgba(0, 0, 255, 0);
    }
    :nth-of-type(4) {
        transform: scale(0.55) rotate(${({ y }) => y * 2.5}deg);
        background: rgba(255, 0, 0, 0);
    }
    :nth-of-type(5) {
        transform: scale(0.4) rotate(${({ y }) => y * 3}deg);
        background: rgba(0, 255, 0, 0);
    } */
`;

const Square2 = styled.div`
    height: 75vw;
    width: 75vw;
    border: 1px solid white;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,${opacity});
    transform: scale(0.85) rotate(${({ y }) => y * 1}deg);
    /* box-shadow: 0px 0px 20px -4px rgba(0,0,0,1); */
`;

const Square3 = styled.div`
    height: 75vw;
    width: 75vw;
    border: 1px solid white;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,${opacity});
    transform: scale(0.8) rotate(${({ y }) => y * 1}deg);
    /* box-shadow: 0px 0px 20px -4px rgba(0,0,0,1); */
`;

const Square4 = styled.div`
    height: 75vw;
    width: 75vw;
    border: 1px solid white;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,${opacity});
    transform: scale(0.75) rotate(${({ y }) => y * 1}deg);
    /* box-shadow: 0px 0px 20px 0px rgba(0,0,0,1); */
`;

const Square5 = styled.div`
    height: 75vw;
    width: 75vw;
    border: 1px solid white;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,${opacity});
    transform: scale(0.7) rotate(${({ y }) => y * 1}deg);
    /* box-shadow: 0px 0px 20px -4px rgba(0,0,0,1); */
`;
