import React from 'react';
import styled from 'styled-components';
import LocomotiveScroll from 'locomotive-scroll';

import StickyMenu from './components/StickyMenu';


let smooth;

export default class SiteLayout extends React.PureComponent {

    componentDidMount() {
        this.initializeSmooth();
    }

    componentDidUpdate() {
        smooth.init();
    }

    initializeSmooth = () => {
        smooth = new LocomotiveScroll({
            el: this.Scroller,
            elMobile: this.Scroller,
            smooth: true,
            smoothMobile: true,
            inertia: 0.5,
            touchMultiplier: 1.5
        });
        smooth.init();
    }

    render() {
        return (
            <Root>
                <StickyMenu />
                <Scroller ref={ref => this.Scroller = ref}>
                    {this.props.children}
                </Scroller>
            </Root>
        );
    }
}

const Root = styled.div`
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
`;

const Scroller = styled.div`
    height: auto;
    width: 100vw;
`;

export { smooth };