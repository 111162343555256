import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { Link } from '@reach/router';
import Media from 'react-media';

import logo from '../images/sticky/logo.svg';
import applemusic from '../images/sticky/applemusic.svg';
import spotify from '../images/sticky/spotify.svg';
import facebook from '../images/sticky/facebook.svg';
import instagram from '../images/sticky/instagram.svg';
import twitter from '../images/sticky/twitter.svg';

import Hamburger from '../css/Hamburger';

import { smooth } from '../SiteLayout';


export default class StickyMenu extends React.PureComponent {

    state = { pose: 'hidden', active: false }

    componentDidMount() {
        setTimeout(() => smooth.on('scroll', this.handleScroll), 100);
    }

    handleScroll = ({ scroll }) => {
        console.log(scroll.y)
        if (scroll.y >= window.innerHeight - 200) {
            this.setState({ pose: 'shown' });
        } else {
            this.setState({ pose: 'hidden' });
        }
    }

    handleToggle = () => {
        this.setState({ active: !this.state.active });
    }

    render() {
        const { pose, active } = this.state;
        return (
            <Root pose={pose}>
                <Branding to='/'>
                    <Logo src={logo} alt='logo' />
                </Branding>
                <Media query='(max-width: 800px)'>
                    {m => !m ? (<>
                        <Navigation>
                            <NavItem to='/'>Home</NavItem>
                            <NavItem to='/'>Narrative</NavItem>
                            <NavItem to='/'>Music</NavItem>
                            <NavItem to='/'>About</NavItem>
                        </Navigation>
                        <MediaLinks>
                            <Space />
                            <Icon src={applemusic} alt='applemusic' />
                            <Icon src={spotify} alt='spotify' />
                        </MediaLinks>
                        <SocialLinks>
                            <Icon src={facebook} alt='facebook' />
                            <Icon src={instagram} alt='instagram' />
                            <Icon src={twitter} alt='twitter' />
                        </SocialLinks>
                    </>) : (
                        <Navigation 
                            style={{ 
                                justifyContent: 'flex-end', 
                                alignItems: 'flex-start',
                                width: 50,
                                margin: -10
                            }}>
                            <Hamburger 
                                onClick={this.handleToggle}
                                active={active}
                                type='squeeze'
                            />
                        </Navigation>
                    )}
                </Media>
            </Root>
        );
    }
}

const Root = posed(styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    pointer-events: none;
    z-index: 1;
`)({
    hidden: {
        opacity: 0,
        transition: {
            type: 'tween',
            duration: 1000,
            ease: 'easeInOut'
        }
    },
    shown: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 1000,
            ease: 'easeInOut'
        }
    }
});

const Branding = styled(Link)`
    position: absolute;
    top: 30px;
    left: 30px;
    pointer-events: all;
`;

const Logo = styled.img`
    height: 60px;
    width: auto;
`;

const Navigation = styled.nav`
    position: absolute;
    width: 320px;
    top: 30px;
    right: 30px;
    pointer-events: all;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const NavItem = styled(Link)`
    font-family: Vollkorn;
    font-size: 18px;
    text-decoration: none;
    color: white;
`;

const MediaLinks = styled.div`
    position: absolute;
    height: 100px;
    bottom: 30px;
    left: 30px;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const SocialLinks = styled.div`
    position: absolute;
    height: 100px;
    bottom: 30px;
    right: 30px;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const Icon = styled.img`
    cursor: pointer;
    height: 20px;
    width: 20px;
`;

const Space = styled.div`
    height: 20px;
    width: 20px;
`;