import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import headshot from '../../images/regulus-background.jpg';
import wordmark from '../../images/regulus-logo.png';

import { smooth } from '../../SiteLayout';


export default class HeroBlock extends React.PureComponent {

    state = { pose: 'hidden', indicator: 'hidden' }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ pose: 'shown', indicator: 'shown' });
            smooth.on('scroll', () => this.setState({ indicator: 'hidden' }));
        }, 800);
    }

    render() {
        const { pose, indicator } = this.state;
        return (
            <Root data-scroll>
                <Headshot src={headshot} alt='headshot' pose={pose} />
                <Wordmark
                    data-scroll
                    data-scroll-speed={-2}
                    src={wordmark}
                    alt='wordmark'
                    pose={pose}
                />
                <Indicator data-scroll data-scroll-speed={2} pose={indicator}>
                    <Label>scroll</Label>
                    <Line />
                </Indicator>
            </Root>
        );
    }
}

const Root = styled.section`
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Headshot = posed(styled.img`
    position: absolute;
    height: auto;
    width: 85%;

    @media screen and (max-width: 500px) {
        width: 150%;
        margin-top: -70px;
        margin-left: 35px;
    }
`)({
    hidden: {
        opacity: 0
    },
    shown: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 2000,
            ease: 'easeIn',
            delay: 1500
        }
    }
});

const Wordmark = posed(styled.img`
    position: absolute;
    height: auto;
    width: 64%;
    margin-top: 4%;

    @media screen and (max-width: 500px) {
        width: 85%;
        margin-top: -20px;
    }
`)({
    hidden: {
        opacity: 0
    },
    shown: {
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 2500,
            ease: 'easeIn'
        }
    }
});

const Indicator = posed(styled.div`
    height: 200px;
    position: absolute;
    right: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 800px) {
        right: 30px;
        margin-bottom: -50px;
    }
    @media screen and (max-width: 500px) {
        margin-bottom: 70px;
    }
`)({
    hidden: {
        bottom: 100,
        opacity: 0,
        transition: {
            type: 'tween',
            duration: 1000,
            ease: 'easeInOut'
        }
    },
    shown: {
        bottom: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 2500,
            ease: 'easeInOut',
            delay: 3000
        }
    }
});

const Label = styled.p`
    font-size: 18px;
    font-family: Vollkorn;
    color: white;
    transform: rotate(270deg);
    text-align: center;
`;

const Line = styled.div`
    height: 150px;
    width: 1px;
    background: white;
`;