import React from 'react';
import styled from 'styled-components';

import DropLines from '../components/DropLines';

import HeroBlock from '../components/blocks/Hero_BLOCK';
import SloganBlock from '../components/blocks/Slogan_BLOCK';
import SquareDecorBlock from '../components/blocks/SquareDecor_BLOCK';



export default function Home() {
    return (
        <Root id='home' data-scroll-section>
            <DropLines />
            <HeroBlock />
            <SloganBlock />
            <SquareDecorBlock />
        </Root>
    );
}

const Root = styled.main`
    height: 3000px;
    position: relative;
    overflow: hidden;
`;