import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';


export default class DropLines extends React.PureComponent {

    state = { pose: 'init' }

    componentDidMount() {
        setTimeout(() => this.setState({ pose: 'dropped' }), 800);
    }

    render() {
        const { pose } = this.state;
        return (
            <LineContainer>
                <Line pose={pose} />
                <Line pose={pose} />
                <Line pose={pose} />
                <Line pose={pose} />
            </LineContainer>
        );
    }
}

const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 100%;
    width: 100vw;
    justify-content: space-evenly;
`;

const Line = posed(styled.div`
    width: 1px;
    background: #f0f0f0;
    opacity: 0.05;
    z-index: 10;
`)({
    init: {
        height: 0
    },
    dropped: {
        height: '100%',
        transition: {
            type: 'tween',
            duration: 3000,
            ease: 'easeIn'
        }
    }
});