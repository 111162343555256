import React from 'react';
import { Location, Router } from '@reach/router';

import SiteLayout from './SiteLayout';

import Home from './pages/Home';


export default class SiteNavivation extends React.PureComponent {
    render() {
        return (
            <Location>
                {(props) => (
                    <SiteLayout location={props.location}>
                        <Router location={props.location}>
                            <Home path='/' />
                        </Router>
                    </SiteLayout>
                )}
            </Location>
        )
    }
}
